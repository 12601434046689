import './App.css'

import {
  AmbientLight,
  AnimationMixer,
  BoxGeometry,
  BufferGeometry,
  Camera,
  Clock,
  DirectionalLight,
  Face3,
  Geometry,
  Group,
  LineSegments,
  Material,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  MeshNormalMaterial,
  MeshPhongMaterial,
  Object3D,
  PerspectiveCamera,
  PointLight,
  Scene,
  SpotLight,
  Vector2,
  Vector3,
  WebGLRenderer,
  WireframeGeometry,
} from 'three'

import {FlyControls} from 'three/examples/jsm/controls/FlyControls'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import Hammer from 'hammerjs'
import React from 'react'
import {TrackballControls} from 'three/examples/jsm/controls/TrackballControls'
import {VRButton} from 'three/examples/jsm/webxr/VRButton'
// @ts-ignore
import WebXRPolyfill from 'webxr-polyfill'
import queryString from 'query-string'
import {useWindowSize} from '@react-hook/window-size'

new WebXRPolyfill()

// don't allow default scroll effects
document.body.addEventListener('touchmove', e => e.preventDefault(), {passive: false})

function asType<T>(ref: T) {
  return ref
}

type PlaneEnd = [Vector3, Vector3]

enum MaterialType {
  Normal,
  Basic,
  Phong,
}

let game: {
  camera: PerspectiveCamera
  cameraRotateDirection: number
  lastPlaneEnd: PlaneEnd
  renderer: WebGLRenderer
  user: Group
} | undefined

const maxCameraRotation = MathUtils.degToRad(45)
const cameraRotationStep = 0.005

type Params = {
  sh?: string | null
  np?: string | null
}
const params = asType<Params>(queryString.parse(window.location.search))

function paramSet(param: string | null | undefined, defaultValue?: boolean) {
  if (param !== undefined) {
    if (param === null || 'true'.indexOf(param) === 0) {
      return true
    }
    if ('false'.indexOf(param) === 0) {
      return false
    }
  }
  return defaultValue
}

const isPublic = asType<boolean>(/^https:\/\/z8q6ajdh.onrender.com(:443)?$/i.test(window.location.origin))

const allowDebug = !isPublic && asType<boolean>(true)
function debug(func: () => any, enabled = true) {
  allowDebug && enabled && func()
}

const usesCloudAssets = asType<boolean>(isPublic)
const getAssetUrl = (path: string) =>
  usesCloudAssets
  ? `https://f001.backblazeb2.com/file/riz-assets-pub/threejs-app/${path}`
  : path

function App() {
  const viewportRef = React.useRef<HTMLDivElement>(null)
  const [width, height] = useWindowSize()
  const [loadProgress, setLoadProgress] = React.useState(0)
  const [debugText, setDebugText] = React.useState('')

  React.useEffect(() => {
    if (viewportRef.current) {
      const viewport = viewportRef.current
      const width = window.innerWidth
      const height = window.innerHeight
      const allowsVr = asType<boolean>(true)
      const scene = new Scene()
      const user = new Group()
      scene.add(user)
      const camera = new PerspectiveCamera(75, width / height, 0.1, 1000)
      // align camera lookAt with user lookAt
      camera.rotateY(Math.PI)
      user.add(camera)
      // TODO: remove
      /*
      // @ts-ignore
      const renderer = new WebGLRenderer({antialias: true, xrCompatible: true})
      */
      const renderer = new WebGLRenderer({antialias: true})
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(width, height)
      if (allowsVr) {
        renderer.xr.enabled = true
        renderer.xr.setReferenceSpaceType('local')
      }
      const planeWidthMin = 0.05
      const planeWidthMax = 0.2
      const planeWidthRange = planeWidthMax - planeWidthMin
      const randomPlaneWidth = () => (Math.random() * planeWidthRange) + planeWidthMin
      game = {
        camera,
        cameraRotateDirection: 1,
        lastPlaneEnd: [new Vector3(0, 0, 0), new Vector3(randomPlaneWidth(), 0, 0)],
        renderer,
        user,
      }
      viewport.appendChild(renderer.domElement)
      if (allowsVr) {
        viewport.appendChild(VRButton.createButton(renderer))
      }

      const controlsStyle = asType<'trackball' | 'fly' | 'custom'>('custom')
      let controls: TrackballControls | FlyControls | undefined
      const userCamera = user as unknown as Camera
      switch (controlsStyle) {
        case 'trackball':
          controls = new TrackballControls(userCamera, renderer.domElement)
          break
        case 'fly':
          controls = new FlyControls(userCamera, renderer.domElement)
          controls.movementSpeed = 2
          controls.rollSpeed = Math.PI / 10
          controls.dragToLook = true
          // default is false
          controls.autoForward = false
          break
        case 'custom':
          break
        default:
          throw new Error()
      }

      const geometry = new BoxGeometry()
      const material = new MeshBasicMaterial({color: 0x00ff00})
      const cube = new Mesh(geometry, material)
      const showCube = asType<boolean>(false)
      if (showCube) {
        scene.add(cube)
      }
      const ambientLight = new AmbientLight(0x101010)
      scene.add(ambientLight)

      // TODO: add to scene
      const directionalLight = new DirectionalLight(0xffffff)

      const light = new PointLight(0xff0000, 0.8, undefined, 1)
      light.position.set(-20, 10, 0)
      // TODO: remove
      //light.castShadow = true
      scene.add(light)

      const light2 = new PointLight(0x0000ff, 0.8, undefined, 1)
      light2.position.set(20, -10, 0)
      // TODO: remove
      //light2.castShadow = true
      scene.add(light2)

      const spotLight = new SpotLight(0x00ff00, 0.6)
      spotLight.lookAt(0, 0, 0)
      spotLight.position.set(30, -20, -20)
      // TODO: remove
      //spotLight.castShadow = true
      scene.add(spotLight)

      let arielMixer: AnimationMixer | undefined
      {
        const loader = new GLTFLoader()

        // TODO: remove
        /*
        // Optional: Provide a DRACOLoader instance to decode compressed mesh data
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
        loader.setDRACOLoader( dracoLoader );
        */

        // Load a glTF resource
        const assetSize = 9696572
        loader.load(
          getAssetUrl('Ariel.glb'),
          // called when the resource is loaded
          gltf => {
            setLoadProgress(1)
            debug(() => console.debug(gltf))
            let objectsToHide = [
              'chest_back_pack_thing',
              'chest_hook_thing',
              'humaan_pelvis',
              'inner_chest_body',
              'inner_chest_tic_tac',
              'inner_head_dibris',
            ]
            if (false && !paramSet(params.sh, true)) {
              objectsToHide = [
                ...objectsToHide,
                'shellL',
                'shellR',
                'shell_strap',
              ]
            }
            if (false && !paramSet(params.np, false)) {
              objectsToHide = [
                ...objectsToHide,
                'nipL',
                'nipR',
              ]
            }
            objectsToHide.forEach(name => {
              const obj = gltf.scene.getObjectByName(name)
              obj && (obj.visible = false)
            })

            arielMixer = new AnimationMixer(gltf.scene)
            const mainAnimation = gltf.animations.find(animation => animation.name === 'tail swim')
            if (mainAnimation) {
              arielMixer.clipAction(mainAnimation).play()
            }

            scene.add(gltf.scene)
          },
          // called while loading is progressing
          xhr => {
            debug(() => xhr.loaded && console.debug(`${xhr.loaded} loaded`))
            const total = xhr.lengthComputable ? xhr.total : assetSize
            if (total > 0) {
              setLoadProgress(xhr.loaded / total)
            }
          },
          // called when loading has errors
          error => {
            setLoadProgress(-1)
            if (!isPublic) {
              console.error('Error loading asset')
              console.error(error)
            }
          }
        )
      }

      let isIdleMode = false
      const toggleIdleMode = () => {
        isIdleMode = !isIdleMode
      }

      let isLookLocked = true
      const toggleLookLocked = () => {
        isLookLocked = !isLookLocked
        if (isLookLocked) {
          user.lookAt(defaultLook)
        }
      }

      let keyDownSet = new Set<string>()

      const keydown = (e: KeyboardEvent) => {
        keyDownSet.add(e.key)
        if (game) {
          if (e.key === 'l') {
            toggleLookLocked()
          } else if (e.key === 'i') {
            toggleIdleMode()
          } else if (e.key === ' ') {
            const minDelta = 0.04
            const maxDelta = 0.2
            const deltaRange = maxDelta - minDelta
            const getDelta = () => {
              return ((Math.random() * deltaRange) + minDelta) * (Math.random() >= 0.5 ? 1 : -1)
            }
            const lastPlaneEndPoint = game.lastPlaneEnd[0]
            const newPlaneEndPoint = new Vector3(
              lastPlaneEndPoint.x + getDelta(),
              lastPlaneEndPoint.y + getDelta(),
              lastPlaneEndPoint.z + getDelta(),
            )
            const newPlaneEndPoint2 = new Vector3(
              newPlaneEndPoint.x + randomPlaneWidth(),
              newPlaneEndPoint.y,
              newPlaneEndPoint.z,
            )
            const newPlaneEnd: PlaneEnd = [newPlaneEndPoint, newPlaneEndPoint2]
            {
              // TODO: dispose when done
              let geometry: Geometry | BufferGeometry
              const useBufferGeometry = asType<boolean>(false)
              if (useBufferGeometry) {
                geometry = new BufferGeometry().setFromPoints([
                  game.lastPlaneEnd[0],
                  game.lastPlaneEnd[1],
                  newPlaneEndPoint2,
                  newPlaneEndPoint,
                ])
                geometry.setIndex([0, 1, 2, 3])
              } else {
                geometry = new Geometry()
                geometry.vertices.push(
                  game.lastPlaneEnd[0],
                  game.lastPlaneEnd[1],
                  newPlaneEndPoint2,
                  newPlaneEndPoint,
                )
                geometry.faces.push(
                  // front
                  new Face3(0, 1, 2),
                  new Face3(2, 3, 0),
                  // back
                  new Face3(2, 1, 0),
                  new Face3(0, 3, 2),
                )
                geometry.computeFaceNormals()
              }
              let material: Material
              const color = 0x0095dd
              const materialType = asType<MaterialType>(MaterialType.Phong)
              switch (materialType) {
                case MaterialType.Normal:
                  material = new MeshNormalMaterial()
                  break
                case MaterialType.Basic:
                  material = new MeshBasicMaterial({color})
                  break
                case MaterialType.Phong:
                  material = new MeshPhongMaterial({color})
                  break
                default:
                  throw new Error()
              }
              const mesh = new Mesh(geometry, material)
              scene.add(mesh)

              const doWireframe = asType<boolean>(false)
              if (doWireframe) {
                const wireframe = new WireframeGeometry(geometry)
                const line = new LineSegments(wireframe)
                scene.add(line)
              }
            }
            game.lastPlaneEnd = newPlaneEnd
          }
        }
      }
      document.addEventListener('keydown', keydown)
      document.addEventListener('keyup', e => keyDownSet.delete(e.key))

      // pointer events
      let mouseIsDown = false
      let didMove = false
      let lastMousePosition = {x: 0, y: 0}

      const hammer = new Hammer(renderer.domElement)
      hammer.get('pinch').set({enable: true})
      hammer.get('rotate').set({enable: true})
      hammer.get('pan').set({direction: Hammer.DIRECTION_ALL})

      renderer.domElement.addEventListener('touchstart', e => e.preventDefault())

      hammer.on('tap', e => {
        if (e.pointerType === 'touch') {
          debug(() => console.debug('tap'), false)
          toggleIdleMode()
        } else {
          debug(() => console.debug('unhandled tap'), false)
        }
      })

      // TODO: remove or figure out a different gesture that doesn't register as a pan
      /*
      hammer.on('swipeleft swiperight swipeup swipedown', e => {
        if (e.pointerType === 'touch') {
          let amount = e.distance * 0.1
          switch (e.type) {
            case 'swipeleft':
              user.translateOnAxis(new Vector3(-1, 0, 0), amount)
              break
            case 'swiperight':
              user.translateOnAxis(new Vector3(1, 0, 0), amount)
              break
            case 'swipeup':
              user.translateOnAxis(new Vector3(0, 1, 0), amount)
              break
            case 'swipedown':
              user.translateOnAxis(new Vector3(0, -1, 0), amount)
              break
          }
        }
      })
      */

      let allowPan = true

      {
        let startAngle = -1
        let lastAngle = -1
        let endAngle = -1
        hammer.on('rotatestart rotate rotateend', e => {
          if (e.pointerType === 'touch') {
            debug(() => console.debug(`rotate:${e.type}`, e), false)
            let angle = -1
            if (e.type === 'rotatestart') {
              startAngle = e.rotation
              lastAngle = -1
              endAngle = -1
            } else if (e.type === 'rotateend') {
              endAngle = e.rotation
              allowPan = false
              // wait a little bit to avoid unintentional pan following rotate
              setTimeout(() => allowPan = true, 250)
            } else {
              angle = e.rotation - lastAngle
              user.rotateZ(MathUtils.degToRad(-angle))
            }
            asType<boolean>(false) && setDebugText(
              `rotate start:${startAngle} end:${endAngle} last:${lastAngle} angle:${angle}`)
            lastAngle = e.rotation
          }
        })
      }

      const pointerDown = (target: {clientX: number, clientY: number}) => {
        debug(() => console.debug('pointerDown'), false)
        mouseIsDown = true
        didMove = false
        lastMousePosition = {x: target.clientX, y: target.clientY}
      }
      renderer.domElement.addEventListener('mousedown', e => pointerDown(e))
      const pointerUp = () => {
        debug(() => console.debug('pointerUp'), false)
        mouseIsDown = false
        if (!didMove) {
          toggleIdleMode()
        }
      }
      document.addEventListener('mouseup', () => pointerUp())

      renderer.domElement.addEventListener('dblclick', () => toggleLookLocked())
      hammer.on('doubletap', e => {
        if (e.pointerType === 'touch') {
          toggleLookLocked()
        }
      })

      /**
       *
       * @param displacement positive is forward
       */
      const moveForwardBackward = (displacement: number) => {
        user.translateOnAxis(new Vector3(0, 0, 1), displacement)
      }

      const pointerMove = (args: {
        deltaX: number
        deltaY: number
      }) => {
        const {deltaX, deltaY} = args
        debug(() => console.debug(`pointerMove`, args), false)
        const factor = 0.005
        const rotationY = deltaX * factor

        const rotationX = deltaY * -factor
        user.rotateY(rotationY)
        user.rotateX(rotationX)
      }
      document.addEventListener('mousemove', e => {
        debug(() => console.debug('mousemove'), false)
        if (mouseIsDown) {
          didMove = true
          const deltaX = e.clientX - lastMousePosition.x
          const deltaY = e.clientY - lastMousePosition.y
          lastMousePosition = {x: e.clientX, y: e.clientY}
          if (e.shiftKey) {
            pointerMove({deltaX, deltaY})
          } else {
            const amount = 0.01
            const displacementX = deltaX * amount
            user.translateOnAxis(new Vector3(1, 0, 0), displacementX)
            const displacementY = deltaY * amount
            user.translateOnAxis(new Vector3(0, 1, 0), displacementY)
          }
        }
      })
      hammer.on('panstart pan panend', e => {
        if (e.pointerType === 'touch') {
          debug(() => console.debug(`pan:${e.type}`, e), false)
          if (!allowPan) {
            return
          }
          asType<boolean>(false) && setDebugText(`pan:${e.type} deltaX:${e.deltaX} deltaY:${e.deltaY}`)
          let {deltaX, deltaY} = e
          if (e.type === 'panend') {
            return
          }
          if (e.type === 'panstart') {
            debug(() => console.debug('panstart'), false)
            lastMousePosition = {x: 0, y: 0}
            return
          }
          deltaX -= lastMousePosition.x
          deltaY -= lastMousePosition.y
          lastMousePosition = {x: e.deltaX, y: e.deltaY}
          const factor = 1
          deltaX *= factor
          deltaY *= factor
          pointerMove({deltaX, deltaY})
        }
      })
      {
        const getPinchDiameter = (touches: Touch[]) => {
          if (touches.length < 2) {
            return
          }
          const touchA = touches[0]
          const touchB = touches[touches.length - 1]
          const a = new Vector2(touchA.clientX, touchA.clientY)
          const b = new Vector2(touchB.clientX, touchB.clientY)
          return a.distanceTo(b)
        }
        let lastScale = -1
        let startScale = -1
        let endScale = -1
        let lastDiameter = -1
        let startDiameter = -1
        let endDiameter = -1
        hammer.on('pinchstart pinch pinchend', e => {
          asType<boolean>(false) && setDebugText(`pinch:${e.type} pointers:${e.pointers.length}`)
          if (e.pointerType === 'touch') {
            debug(() => console.debug(`pinch:${e.type}`, e), false)
            debug(() => setDebugText(`pinch:${e.type}`), false)
            const newDiameter = getPinchDiameter(e.pointers)
            if (newDiameter === undefined) {
              return
            }
            let scale = -1
            let diameter = -1
            if (e.type === 'pinchstart' || lastDiameter < 0) {
              startScale = e.scale
              lastScale = -1
              endScale = -1
              startDiameter = newDiameter
              lastDiameter = -1
              endDiameter = -1
            } else if (e.type === 'pinchend') {
              endScale = e.scale
              endDiameter = newDiameter
            } else {
              scale = e.scale - lastScale
              diameter = newDiameter - lastDiameter
              let displacement
              if (asType<boolean>(false)) {
                // TODO: maybe remove the scale stuff since we're not using it
                displacement = scale * 1.5
              } else {
                displacement = diameter * 0.02
              }
              moveForwardBackward(displacement)
            }
            asType<boolean>(false) && setDebugText(
              `pinch start:${startScale} end:${endScale} last:${lastScale} scale:${scale}`)
            asType<boolean>(false) && setDebugText(
              `pinch start:${startDiameter} end:${endDiameter} last:${lastDiameter} diameter:${scale}`)
            lastScale = e.scale
            lastDiameter = newDiameter
          }
        })
      }
      renderer.domElement.addEventListener('wheel', e => {
        e.preventDefault()

        if (e.ctrlKey) {
          // trackpad zoom/scale
          debug(() => console.debug('wheel.ctrl', e.deltaY), false)
          const displacement = e.deltaY * -0.05
          moveForwardBackward(displacement)
        } else if (e.shiftKey) {
          debug(() => console.debug('wheel.shift', e.deltaX, e.deltaY), false)
          let deltaX = -e.deltaX
          let deltaY = -e.deltaY
          const factor = 0.5
          deltaX *= factor
          deltaY *= factor
          pointerMove({deltaX, deltaY})
        } else {
          debug(() => console.debug('wheel', e.deltaX, e.deltaY), false)
          const amount = 0.01
          const displacement = -e.deltaY * amount
          moveForwardBackward(displacement)
          if (asType<boolean>(false)) {
            const displacementX = -e.deltaX * amount
            user.translateOnAxis(new Vector3(1, 0, 0), displacementX)
          }
        }
      })

      let lightData = [
        {
          light: light,
          velocity: 0.3,
          maxX: 30,
          minX: -40,
        },
        {
          light: light2,
          velocity: -0.2,
          maxX: 40,
          minX: -30,
        },
      ]
      let defaultLook = new Vector3(0, 3, 0)
      const clock = new Clock()
      user.position.set(0, 4, 3)
      user.lookAt(defaultLook)
      let isRunning = true
      const usesRequestAnimationFrame = asType<boolean>(allowsVr)
      const animate = () => {
        const delta = clock.getDelta()
        if (!isRunning) {
          return
        }
        usesRequestAnimationFrame && requestAnimationFrame(animate)
        if (game) {
          const rotateCubeX = asType<boolean>(false)
          if (rotateCubeX) {
            cube.rotation.x += 0.001
          }
          cube.rotation.y += 0.01
          const doAutoCameraRotate = asType<boolean>(false)
          if (doAutoCameraRotate) {
            user.rotation.y += cameraRotationStep * game.cameraRotateDirection
            if (user.rotation.y > maxCameraRotation) {
              game.cameraRotateDirection *= -1
              user.rotation.y = maxCameraRotation
            } else if (user.rotation.y < -maxCameraRotation) {
              game.cameraRotateDirection *= -1
              user.rotation.y = -maxCameraRotation
            }
          }
          lightData.forEach(data => {
            data.light.position.x += data.velocity
            if (data.light.position.x > data.maxX) {
              data.velocity *= -1
              data.light.position.x = data.maxX
            } else if (data.light.position.x < data.minX) {
              data.velocity *= -1
              data.light.position.x = data.minX
            }
          })
          if (arielMixer) {
            arielMixer.update(delta)
          }
          if (controls) {
            controls.update(delta)
          }
          if (controlsStyle === 'custom') {
            const moveAmount = delta * 3
            const rotateAmount = delta * 1
            if (keyDownSet.has('w')) {
              user.translateOnAxis(new Vector3(0, 0, 1), moveAmount)
            }
            if (keyDownSet.has('s')) {
              user.translateOnAxis(new Vector3(0, 0, -1), moveAmount)
            }
            if (keyDownSet.has('d')) {
              user.translateOnAxis(new Vector3(-1, 0, 0), moveAmount)
            }
            if (keyDownSet.has('a')) {
              user.translateOnAxis(new Vector3(1, 0, 0), moveAmount)
            }
            if (keyDownSet.has('e')) {
              user.rotateZ(rotateAmount)
            }
            if (keyDownSet.has('q')) {
              user.rotateZ(-rotateAmount)
            }
            if (keyDownSet.has('ArrowRight')) {
              user.rotateY(-rotateAmount)
            }
            if (keyDownSet.has('ArrowLeft')) {
              user.rotateY(rotateAmount)
            }
            if (keyDownSet.has('ArrowUp')) {
              user.rotateX(-rotateAmount)
            }
            if (keyDownSet.has('ArrowDown')) {
              user.rotateX(rotateAmount)
            }
          }

          if (isIdleMode) {
            const rotationTheta = delta * 0.3
            rotateAboutPoint(user, defaultLook, new Vector3(0, 1, 0), rotationTheta, true)
            if (isLookLocked) {
              user.lookAt(defaultLook)
            }
          }
          renderer.render(scene, camera)
        }
      }
      if (usesRequestAnimationFrame) {
        animate()
      } else {
        renderer.setAnimationLoop(animate)
      }
      return () => {
        isRunning = false
        document.removeEventListener('keydown', keydown)
        if (showCube) {
          scene.remove(cube)
        }
        scene.remove(spotLight)
        scene.remove(light)
        scene.remove(light2)
        scene.remove(ambientLight)
        geometry.dispose()
        material.dispose()
        viewport.removeChild(renderer.domElement)
      }
    }
  }, [])
  React.useEffect(() => {
    if (game) {
      const {camera, renderer} = game
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderer.setSize(width, height)
    }
  }, [width, height])
  return (
    <>
      {loadProgress < 1 && (
        <div style={{textAlign: 'center'}}>
          {loadProgress < 0
            ? 'Error loading'
            : `${(loadProgress * 100).toFixed(0)}% Loaded`
          }
        </div>
      )}
      <div ref={viewportRef} />
      {!isPublic && debugText && (
        <div style={{
          position: 'absolute',
          width: '100%',
          color: 'white',
          fontSize: '7px',
          top: 0,
        }}>{debugText}</div>
      )}
    </>
  )
}

// obj - your object (THREE.Object3D or derived)
// point - the point of rotation (THREE.Vector3)
// axis - the axis of rotation (normalized THREE.Vector3)
// theta - radian value of rotation
// pointIsWorld - boolean indicating the point is in world coordinates (default = false)
function rotateAboutPoint(obj: Object3D, point: Vector3, axis: Vector3, theta: number, pointIsWorld = false) {
  pointIsWorld = (pointIsWorld === undefined)? false : pointIsWorld;

  if (pointIsWorld && obj.parent) {
    obj.parent.localToWorld(obj.position); // compensate for world coordinate
  }

  obj.position.sub(point); // remove the offset
  obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
  obj.position.add(point); // re-add the offset

  if (pointIsWorld && obj.parent) {
    obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
  }

  obj.rotateOnAxis(axis, theta); // rotate the OBJECT
}

export default App
